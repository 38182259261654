import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import PartnerArea from "@containers/partner/layout-01";
import IntroArea from "@components/introarea/layout-1";
import Slider3D from "@containers/global/slider-3d";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import BoxSection from "@components/BoxSection/layout-one/layout-2";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import FaqArea from "@containers/faq/layout-03";
import ContactArea from "@containers/contact-us";

const MultilingualDesktopPublishingPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Turnkey Multilingual Digital &amp; Desktop Publishing Services" description="We provide expert multilingual desktop and digital publishing services in over 80 languages in all major publishing tools. Contact us today for more information." />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader
          data={content["technical-translation-header-section"]}
          pageContext={content["technical-translation-meta-description"]}
        />
        <PartnerArea data={content["multilingual-desktop-publishing-logo"]} />        
        <IntroArea data={content["technical-desktop-intro"]} />
        <Slider3D layout={2} data={content["app-localization-applications"]} />
        <IntroAreaReverse data={content["internationalization-body"]} />
        <BoxSection layout={2} data={content["app-markting-body"]} />
        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["technical-translation-faq-body"]} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query multilingualDesktopPublishingPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "multilingual-desktop-publishing" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

MultilingualDesktopPublishingPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default MultilingualDesktopPublishingPage;
